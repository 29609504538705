// @flow
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List } from 'immutable';
import { useIntl } from 'react-intl';

import { userSelector } from 'domain/env';
import {
  organizationUsersSelector,
  bulkAssignOrganizationUsers,
  bulkRevokeOrganizationUsers,
} from 'domain/organizationUser';
import { currentCompanySelector } from 'domain/documents/documentSelector';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import SearchBase from 'components/mui/Layouts/components/AppbarSearchBase';
import SelectPanel from 'pages/configurations/components/SelectPanel';
import NameCell from 'pages/configurations/components/Table/components/NameCell';
import ActionCell from './components/ActionCell';
import Table from 'pages/configurations/components/Table';

import type { EnhancedUser } from 'domain/organizationUser/types.js.flow';
import { relevantSearch } from 'lib/helpers';
import { getUserDisplayName, sortUsers } from 'pages/configurations/helpers';

import { SUPPLIER_ROLES } from './constants';
import { INITIAL_BULK_ACTIONS_CONFIG } from 'pages/configurations/components/SelectPanelWrapper/constants';

const mapStateToProps = (state) => ({
  currentUser: userSelector(state),
  users: organizationUsersSelector(state),
  companyId: currentCompanySelector(state),
});

const CompanyUsersList: React$StatelessFunctionalComponent = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [searchText, setSearchText] = useState('');
  const [selected, setSelected] = useState([]);
  const [bulkActionsConfig, setBulkActionsConfig] = useState({ ...INITIAL_BULK_ACTIONS_CONFIG });

  const { users, currentUser, companyId } = useSelector(mapStateToProps);

  const isDisabledFn = useCallback(
    (user: EnhancedUser) => user.email === currentUser.userId || user.admin,
    [currentUser],
  );

  const columns = useMemo(
    () => [
      {
        id: 'fullName',
        label: formatMessage({ id: 'configurations.company.users.tableHeader.fullName', defaultMessage: 'Full name' }),
        styles: { minWidth: 170 },
        component: (user: EnhancedUser) => <NameCell name={getUserDisplayName(user)} picture={user.pic} />,
      },
      {
        id: 'email',
        label: formatMessage({ id: 'configurations.company.users.tableHeader.email', defaultMessage: 'Email' }),
        styles: { minWidth: 100 },
      },
      {
        id: 'display_role',
        label: formatMessage({ id: 'configurations.company.users.tableHeader.role', defaultMessage: 'Role' }),
        styles: { minWidth: 170 },
      },
      {
        id: 'phone',
        label: formatMessage({ id: 'configurations.company.users.tableHeader.phone', defaultMessage: 'Phone number' }),
        styles: { minWidth: 170 },
      },
      {
        id: 'action',
        label: formatMessage({ id: 'configurations.company.users.tableHeader.action', defaultMessage: 'Action' }),
        styles: { minWidth: 235 },
        component: (user: EnhancedUser) => <ActionCell user={user} companyId={companyId} isDisabledFn={isDisabledFn} />,
      },
    ],
    [formatMessage, companyId, isDisabledFn],
  );

  const usersList = useMemo(
    () =>
      new List(
        sortUsers(
          searchText
            ? relevantSearch(
                searchText,
                users,
                (user) => `${user.display_name} ${user.email} ${user.role} ${user.phone}`,
              )
            : users,
        ),
      ),
    [users, searchText],
  );

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        const newSelected = usersList.toJS().map(({ id }) => id);
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    },
    [usersList],
  );

  const onClearSelected = useCallback(() => {
    setSelected([]);
  }, []);

  const onChangeSearch = useCallback((text: string) => setSearchText(text), []);

  const onBulkAssign = useCallback(() => {
    new Promise((resolve, reject) => {
      dispatch(bulkAssignOrganizationUsers({ data: { userIds: selected, companyId }, resolve, reject }));
    }).then(onClearSelected);
  }, [dispatch, selected, companyId, onClearSelected]);

  const onBulkRevoke = useCallback(() => {
    new Promise((resolve, reject) => {
      dispatch(bulkRevokeOrganizationUsers({ data: { userIds: selected, companyId }, resolve, reject }));
    }).then(onClearSelected);
  }, [dispatch, selected, companyId, onClearSelected]);

  useEffect(() => {
    if (users.size && selected.length) {
      const selectedUsers = users.filter((u) => selected.includes(u.id));
      const actionsConfig = { ...INITIAL_BULK_ACTIONS_CONFIG };
      selectedUsers.forEach((user) => {
        if (!SUPPLIER_ROLES.includes(user.role)) {
          if (user.companies_id.has(companyId)) {
            actionsConfig.bulkRevokeAvailable = true;
            if (actionsConfig.isDisableBulkRevoke) {
              actionsConfig.isDisableBulkRevoke = isDisabledFn(user);
            }
          } else {
            actionsConfig.bulkAssignAvailable = true;
            if (actionsConfig.isDisableBulkAssign) {
              actionsConfig.isDisableBulkAssign = isDisabledFn(user);
            }
          }
        }
      });
      setBulkActionsConfig(actionsConfig);
    }
  }, [selected, users, companyId, isDisabledFn]);

  return (
    <>
      <Paper sx={{ flex: '1 1 0', overflowY: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <Box p={2}>
          <SearchBase value={searchText} onChange={onChangeSearch} />
        </Box>
        <Table
          selected={selected}
          data={usersList}
          columns={columns}
          handleSelectAllClick={handleSelectAllClick}
          setSelected={setSelected}
          withCheckbox
        />
      </Paper>

      <SelectPanel
        isVisible={!!selected.length}
        count={selected.length}
        bulkActionsConfig={bulkActionsConfig}
        onClearSelected={onClearSelected}
        onBulkAssign={onBulkAssign}
        onBulkRevoke={onBulkRevoke}
        countTitle={formatMessage({
          id: 'configurations.company.users.selectedPanel.title',
          defaultMessage: 'Users selected',
        })}
      />
    </>
  );
};

export default CompanyUsersList;
