import { select, put, take, fork, takeEvery, cancel, takeLatest, spawn, call } from 'redux-saga/effects';
import { navigate } from 'domain/router/redux/reduxActions';
import { generatePath } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import * as Env from 'domain/env/sagas';
import * as Router from 'domain/router';
import * as ACL from 'domain/restriction';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { isMatchRoute } from 'domain/router/utils';
import { asyncErrorAction } from 'components/Form/validation';
import { expiredAction, cognitoAuthInitAction } from 'domain/env';
import { storage } from 'lib/storage';
import { notify } from 'lib/errorLogger';
// eslint-disable-next-line import/no-cycle
import { cognitoAuthHandler } from 'domain/env/sagas';
import { Auth } from 'aws-amplify';
import { isAuthenticated } from 'amplify/helpers';
import { cognitoAuthAppUrl } from 'amplify/awsauth';

const PROHIBIDED_BACK_URL = ['resetpasswd', 'setpasswd', 'forgotPassword'];

// 24 hours * 60 min * 60 sec * 1000 ms
const DAY = 86400000;
const clearOldCacheDocuments = async () => {
  try {
    const cache = await caches.open('documents');
    if (cache) {
      cache.keys().then((keys) => {
        keys.forEach(async (request) => {
          const response = await cache.match(request);
          if (response) {
            const cacheAge = Date.parse(new Date()) - Date.parse(response.headers.get('date'));
            if (cacheAge > DAY) {
              cache.delete(request);
            }
          }
        });
      });
    }
  } catch (err) {
    notify.captureEvent(err);
  }
};

const getCurrentUrl = () => window.location.pathname + window.location.search;

const getCurrentRoute = () => Object.values(ROUTES_PATH).find((route) => isMatchRoute(route.absolute));
const getAllowedRoute = (restriction) => {
  const route = getCurrentRoute();
  return route ? ACL.check(route.restriction, restriction) : null;
};

const isProhibitedBackUrl = (route) => (route ? PROHIBIDED_BACK_URL.includes(route.id) : true);

function* watchSignIn() {
  const task = yield takeLatest(Env.action.signInAction.type, Env.ensureSignInWithUserProfile);
  yield take(Env.action.signInAction.success);
  yield put(Router.completeEmptyAction('auth'));
  yield cancel(task);
  yield spawn(watchSignOut); // eslint-disable-line no-use-before-define
}

function* watchSignOut() {
  const task = yield takeEvery(Env.action.signOutAction.type, Env.ensureSignOut);
  // silent signout should triger watchSignin to allow for further signin
  yield takeLatest(Env.action.silentSignOutAction.success, watchSignIn);
  const { payload } = yield take(Env.action.signOutAction.success);
  clearOldCacheDocuments();
  if (!(window.location.pathname === generatePath(ROUTES_PATH.MAINTENANCE.absolute))) {
    // we set backUrl if we were not logged out by 403 in interceptor. In case of 403 we
    // should not set it as user will be redirected to backUrl and 403 indicates he has
    // no access so we are running into login logout cycle
    try {
      const res = yield call([Auth, 'signOut']);
    } catch (error) {
      console.log(error);
    }

    if (
      (payload && payload.reason === process.env.REACT_APP_ACCESS_DENIED_CODE) ||
      isProhibitedBackUrl(getCurrentRoute())
    ) {
      storage().backUrl().set('');
      window.location = cognitoAuthAppUrl;
    } else {
      // SSO, in case user if logged-out when expired token is used to get userProfile
      storage().backUrl().set(getCurrentUrl());
      window.location = cognitoAuthAppUrl;
    }
  }
}

function* watchExpired() {
  yield put(navigate.replace(generatePath(ROUTES_PATH.EXPIRED.absolute)));
}

export default function* ensureSign() {
  clearOldCacheDocuments();
  yield takeLatest(expiredAction.type, watchExpired);
  // watches signIn event emited by amplify event Hub
  yield takeLatest(cognitoAuthInitAction.type, cognitoAuthHandler);
  if (yield call(isAuthenticated)) {
    yield fork(watchSignOut);
  } else {
    // fix for https://dokka-ai.atlassian.net/browse/DA-8672
    // problem: if there is a token without a role in the LS then we have a white screen permanently.
    // decision: keep this couple in a consistent state in the case when a user is hypothetically not authorized
    if (storage().token().get()) {
      storage().token().set('');
      const { href } = window.location;
      window.location.href = href;
    }

    const restriction = yield select(ACL.myRestriction);
    const allowedRoute = getAllowedRoute(restriction);

    if (!(allowedRoute && allowedRoute.id !== '404')) {
      // persist backurl for SSO
      const currentURL = getCurrentUrl();
      storage().backUrl().set(currentURL);
      yield put({ type: Env.action.savePersistedBackUrlAction.type, payload: currentURL });
      window.location = cognitoAuthAppUrl;
    }
    yield fork(watchSignIn);
  }
}
