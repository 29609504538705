// @flow
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { assignOrganizationUser, deleteOrganizationUser, revokeOrganizationUser } from 'domain/organizationUser';

import Button from '@mui/material/Button';
import RemoveIcon from '@mui/icons-material/Remove';
import AssigmentButton from 'pages/configurations/components/AssigmentButton';

import type { EnhancedUser } from 'domain/organizationUser/types.js.flow';

import { SUPPLIER_ROLES } from '../../constants';

type TProps = {
  user: EnhancedUser,
  companyId: string,
  isDisabledFn: (user: EnhancedUser) => boolean,
};

const ActionCell: React$StatelessFunctionalComponent<TProps> = ({ user, companyId, isDisabledFn }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const isSupplier = SUPPLIER_ROLES.includes(user.role);

  const onDelete = useCallback(() => {
    dispatch(deleteOrganizationUser({ id: user.id }));
  }, [dispatch, user.id]);

  const handleUserAssignment = useCallback(() => {
    const { companies_id: companiesId, id } = user;
    const assigned = companiesId.has(companyId);
    if (assigned) dispatch(revokeOrganizationUser({ data: { id } }));
    else dispatch(assignOrganizationUser({ data: { id } }));
  }, [dispatch, companyId, user]);

  return (
    <>
      {isSupplier ? (
        <Button
          size="small"
          color="error"
          onClick={onDelete}
          startIcon={<RemoveIcon />}
          variant="outlined"
          disabled={user.companies_id.size > 1}
        >
          {formatMessage({ id: 'button.revoke', defaultMessage: 'revoke' })}
        </Button>
      ) : (
        <AssigmentButton
          disabled={isDisabledFn(user)}
          assigned={user.companies_id.has(companyId)}
          onClick={handleUserAssignment}
        />
      )}
    </>
  );
};

export default ActionCell;
