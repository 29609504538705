// @flow
import * as React from 'react';
import { compose } from 'redux';
import { throttle } from 'throttle-debounce';
import { listLoop } from 'lib/helpers';
import { updateStyle } from 'lib/domHelpers';
import { getTransform, angles, getPolygonStyleFactory, getPropName } from 'pages/document/DocumentComponent/helpers';
import { DimensionsFactory } from 'pages/document/page/components/ApprovalStampManagement/helpers';

import { TextractTableContainer } from 'components/TextractCanvas/container';

import type { HTMLPolygonType } from '../polygons/helpers';
import { type RecordOf, is } from 'immutable';
import type { RenderSuccessData } from 'react-pdf';
import type { PageParams, DimensionPropsType } from '../types.js.flow';
import type { AngleType } from 'lib/pdfHelpers';
import type { IntlShape } from 'react-intl';
import type { TUiAFStampMode } from 'domain/ui/types.js.flow';

import { Page as PDFPage } from 'react-pdf';
import Polygons from '../polygons/index';
import Grid from './components/Grid';
import ApprovalStampManagement from 'pages/document/page/components/ApprovalStampManagement';
import PaidBadge from 'pages/document/page/components/PaidBadge';
import DocumentPageControls from '../components/DocumentPageControls';
import { PageArea } from 'pages/document/page/components/StyledComponents';

import sheet from './sheet';
import { withStyles } from '@mui/styles';

const stopPropagationCallback = (cb: () => void) => (e: MouseEvent) => {
  e.stopPropagation();
  cb();
};

type Props = {|
  document: string,
  polygons: any,
  classes: {
    [key: string]: string,
  },
  params: RecordOf<PageParams>,
  container: ?HTMLElement,
  isFinancial: boolean,
  isPaid: boolean,
  isVisibleStamp: boolean,
  width: number,
  scale: number,
  stampMode: TUiAFStampMode,
  idx: number,
  total: ?number,
  onScale: (mx: number) => void,
  onRotate: (i: number, s: AngleType) => void,
  onRenderSuccess: (d: string, p: RenderSuccessData) => void,
  isStatement: boolean,
  intl: IntlShape,
  showGridBetaLabel: () => void,
  onZoom: (direction: 1 | -1 | 0) => void,
  onReset: () => void,
  getIsDisabledZoomButton: (direction: 1 | -1 | 0) => boolean,
  textractEnabledForDocument: boolean,
  recognizedRotation: number,
|};

type Position = {|
  x: number,
  y: number,
|};

type State = {|
  showGrid: boolean,
  isSelection: boolean,
  // original first page size
  originalFirstPageSize: {|
    height: null | number,
    width: null | number,
  |},
|};

type ContainerStyle = {
  width: number,
  height: number,
};

export const BORDER_WIDTH = 30;
export const MAX_DOC_WIDTH = 870;

class Page extends React.Component<Props, State> {
  selectionArea = null;

  wrapper: ?HTMLElement;

  container: ?HTMLElement;

  gridButtonRef: ?HTMLElement;

  startPosition: ?Position = { x: 0, y: 0 };

  position: Position = { x: 0, y: 0 };

  wheel: number;

  shiftPositionX: number = 50;

  _isMove: boolean;

  constructor(props) {
    super(props);

    this.wheel = props.scale;

    this.state = {
      showGrid: false,
      isSelection: false,
      originalFirstPageSize: {
        height: null,
        width: null,
      },
    };
  }

  static getDerivedStateFromProps(props: Props) {
    const { isStatement } = props;

    if (isStatement) return null;
    return { showGrid: false };
  }

  componentDidMount() {
    /* global.addEventListener('keydown', this.handleKey, false);
    global.addEventListener('keyup', this.handleKey, false); */
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const {
      polygons,
      params,
      isVisibleStamp,
      stampMode,
      scale,
      width,
      isStatement,
      isFinancial,
      isPaid,
      textractEnabledForDocument,
    } = this.props;

    const { showGrid, isSelection } = this.state;

    if (!is(polygons, nextProps.polygons)) return true;
    if (!is(params, nextProps.params)) return true;
    if (isVisibleStamp !== nextProps.isVisibleStamp) return true;
    if (stampMode !== nextProps.stampMode) return true;
    if (scale !== nextProps.scale) return true;
    if (width !== nextProps.width) return true;
    if (isStatement !== nextProps.isStatement) return true;
    if (isFinancial !== nextProps.isFinancial) return true;
    if (isPaid !== nextProps.isPaid) return true;
    if (textractEnabledForDocument !== nextProps.textractEnabledForDocument) return true;

    if (showGrid !== nextState.showGrid) return true;
    if (isSelection !== nextState.isSelection) return true;
    return false;
  }

  componentDidUpdate(prevProps: Props) {
    const { scale } = this.props;
    if (scale !== prevProps.scale) {
      this.shiftPositionX = scale === 1 ? 50 : this.shiftPositionX;
      this.scalePosition();
    }
  }

  componentWillUnmount() {
    global.removeEventListener('keydown', this.handleKey);
    global.removeEventListener('keyup', this.handleKey);
  }

  onRenderSuccess = (document: string) => (props: RenderSuccessData) => {
    const { idx, onRenderSuccess } = this.props;
    const { _pageInfo } = props;
    const { view } = _pageInfo;

    // convert posible nagative values "width" (index 2) and "height" ( index 3 ) to positive values
    const absView = view.map((val, index) => (index > 1 ? Math.abs(val) : val));

    if (idx === 0) {
      this.setState({
        originalFirstPageSize: {
          width: absView[2],
          height: absView[3],
        },
      });
    }

    onRenderSuccess(document, {
      ...props,
      pageInfo: { ..._pageInfo, view: absView },
      pageIndex: idx,
    });
  };

  get scrollTop(): number {
    const { container } = this.props;
    return container ? container.scrollTop : 0;
  }

  get style(): ContainerStyle {
    const {
      params: { height },
      scale,
    } = this.props;
    return {
      width: Math.ceil(this.calculatePageWidth() * scale),
      height: Math.ceil(height),
      transform: getTransform(this.position.x),
    };
  }

  get isMove() {
    return this._isMove;
  }

  get rotateAngle(): number {
    const { params } = this.props;
    const { rotate, embdRotate } = params;

    return (rotate + embdRotate) % 360;
  }

  get tooltipContainer() {
    const { classes, idx } = this.props;
    return document.getElementsByClassName(classes.tooltipContainer)[idx];
  }

  set isMove(state) {
    this._isMove = state;
    this.updateCursor(state ? '-webkit-grabbing' : null);
  }

  get scaledWidth() {
    const { scale } = this.props;
    return this.calculatePageWidth() * scale;
  }

  get ratioX() {
    const {
      polygons,
      idx,
      params: { rotate },
    } = this.props;
    const realPage = { width: parseFloat(polygons.width), height: parseFloat(polygons.height) };

    if (Number.isNaN(realPage.width) || Number.isNaN(realPage.height)) {
      throw new Error(`Incorrect width ${polygons.width} or height ${polygons.height} in page ${idx + 1}`);
    }

    const propWidth = ((getPropName('width', rotate): any): DimensionPropsType);
    return this.scaledWidth / realPage[propWidth];
  }

  getStyledPolygon = (polygon): HTMLPolygonType => {
    const {
      params: { height, rotate },
    } = this.props;
    return getPolygonStyleFactory(this.scaledWidth, height, rotate, this.ratioX, polygon);
  };

  getStyledPolygons = (): Array<HTMLPolygonType> => {
    const { polygons } = this.props;

    return polygons.data.reduce(
      (array, polygon) =>
        array.concat([
          {
            ...this.getStyledPolygon(polygon),
            text: polygon.text,
            id: polygon.id,
          },
        ]),
      [],
    );
  };

  getShftSizeX = () => {
    const { scale, width, container } = this.props;
    const viewPortSize = container ? container.offsetWidth : width;
    const currentPageWidth = this.calculatePageWidth() * scale;
    return Math.max(currentPageWidth - viewPortSize, 0);
  };

  setArea = (area) => {
    this.selectionArea = area;
  };

  setInitialGridPosition = (offsetY) => {
    this.mouseYPosition = offsetY;
  };

  setGridButtonRef = (gridButtonRef) => {
    this.gridButtonRef = gridButtonRef;
  };

  scalePosition = () => {
    const shiftX = -Math.abs(Math.max(this.getShftSizeX(), 0)) * (this.shiftPositionX / 100);
    this.position = { ...this.position, x: shiftX };
  };

  updateShiftPositionX = (shiftPx: number) => {
    this.shiftPositionX = Math.max(Math.min(100, (100 / this.getShftSizeX()) * Math.abs(shiftPx)), 0);
  };

  movePage = () => {
    if (this.container && this.isMove) {
      const { x, y } = this.position;
      const { container } = this.props;

      updateStyle(this.container, [{ name: 'transform', value: `translate3d(${x}px, ${0}px, 0)` }]);

      if (container) {
        container.scrollTo(0, y);
      }
    }
  };

  isShowButtons = () => {
    const { showGrid, isSelection } = this.state;
    const { stampMode } = this.props;
    // hide action buttons when we move approval stamp
    return !showGrid && !isSelection && stampMode !== 'move';
  };

  handleKey = (e: KeyboardEvent) => {
    if (e.metaKey || e.ctrlKey) {
      this.updateCursor('default');
    } else {
      this.updateCursor(null);
      if (this.selectionArea) {
        this.selectionArea.stop();
      }
    }
  };

  handleMouseDown = (e: SyntheticMouseEvent<HTMLDivElement>) => {
    const { x } = this.position;
    if ((e.metaKey || e.ctrlKey) && this.selectionArea) {
      this.setState({ isSelection: true });
      const { layerX, layerY } = e.nativeEvent;
      this.selectionArea.start(layerX - x, layerY);
      return;
    }
    this.startPosition = {
      x: x ? e.clientX - x : e.clientX,
      y: this.scrollTop + e.clientY,
    };
    if (this.selectionArea) {
      this.selectionArea.areaClean();
    }
    this.isMove = true;
  };

  handleMouseMove = (event: SyntheticMouseEvent<HTMLElement>) => {
    const { isFinancial } = this.props;

    const { nativeEvent, metaKey, ctrlKey, clientX, clientY } = event;
    const { layerX, layerY, offsetY } = nativeEvent;

    if (isFinancial) this.setInitialGridPosition(offsetY);
    if ((metaKey || ctrlKey) && this.selectionArea) {
      this.selectionArea.selectionAreaUpdate(layerX - this.position.x, layerY);
    }
    if (this.isMove) {
      const { x, y } = this.startPosition;
      const shiftSizeX = this.getShftSizeX();
      const moveX = clientX - x;
      const posX = shiftSizeX > 0 ? Math.max(-Math.abs(shiftSizeX), Math.min(moveX, 0)) : 0;
      this.updateShiftPositionX(posX);
      this.position = {
        x: posX,
        y: y - clientY,
      };
      window.requestAnimationFrame(this.movePage);
    }
  };

  handleMouseUp = () => {
    if (this.selectionArea && this.selectionArea.area) {
      this.selectionArea.stop();
      this.setState({ isSelection: false });
    }
    this.isMove = false;
  };

  handleScale = () => {
    const { scale, onScale } = this.props;
    onScale(scale + this.wheel);
  };

  // eslint-disable-next-line react/sort-comp
  throttleedScale = throttle(200, this.handleScale);

  handleWheel = (event: SyntheticWheelEvent<HTMLElement>) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();
      const { deltaY } = event;
      this.wheel = -(deltaY / Math.abs(deltaY)) * 0.05; // TODO: Normalize Wheel
      this.throttleedScale();
    }
  };

  calculatePageWidth = () => {
    const { width } = this.props;
    return width > MAX_DOC_WIDTH + BORDER_WIDTH ? MAX_DOC_WIDTH : width - BORDER_WIDTH;
  };

  handleGrid = () => {
    const { showGridBetaLabel } = this.props;
    this.setState(({ showGrid }) => {
      showGridBetaLabel(!showGrid);
      return { showGrid: !showGrid };
    });
  };

  updateCursor = (value: string | null) => {
    if (this.container) {
      updateStyle(this.container, [{ name: 'cursor', value }]);
    }
  };

  render() {
    const {
      classes,
      params,
      idx,
      onRotate,
      scale,
      isFinancial,
      polygons,
      document,
      isStatement,
      isPaid,
      isVisibleStamp,
      total,
      onZoom,
      onReset,
      getIsDisabledZoomButton,
      width,
      textractEnabledForDocument,
      recognizedRotation,
    } = this.props;

    const { showGrid, originalFirstPageSize } = this.state;
    const loop = listLoop(params.rotate / 90, angles);
    const pageContainerId = `pdfPage-${idx + 1}`;
    const deltaAngle = (360 - recognizedRotation + params.rotate) % 360;

    const onRotateLeft = stopPropagationCallback(() => onRotate(idx, loop.prev));
    const onRotateRight = stopPropagationCallback(() => onRotate(idx, loop.next));

    const isSmallActionButton = width <= 450;

    return (
      <div className={classes.pageContainer}>
        <div
          className={classes.pageWrapper}
          ref={(el) => {
            this.wrapper = el;
          }}
        >
          <PageArea
            id={pageContainerId}
            role="presentation"
            paid={isPaid}
            style={this.style}
            onMouseDown={this.handleMouseDown}
            onMouseMove={this.handleMouseMove}
            onMouseLeave={this.handleMouseUp}
            onMouseUp={this.handleMouseUp}
            onWheel={this.handleWheel}
            ref={(el) => {
              this.container = el;
            }}
          >
            {isPaid && <PaidBadge docWidth={width} />}

            <PDFPage
              renderTextLayer={false}
              width={this.calculatePageWidth()}
              onRenderSuccess={this.onRenderSuccess(document)}
              rotate={this.rotateAngle}
              scale={scale}
              renderAnnotationLayer
              pageNumber={idx + 1}
            />

            {polygons.get('data').size && (
              <Polygons
                polygons={polygons}
                getStyledPolygons={this.getStyledPolygons}
                getStyledPolygon={this.getStyledPolygon}
                pageNumber={idx + 1}
                scale={scale}
                rotate={params.rotate}
                width={this.calculatePageWidth()}
                height={params.height}
                setArea={this.setArea}
                wrapper={this.wrapper}
                tooltipContainer={this.tooltipContainer}
              />
            )}
            {textractEnabledForDocument && (
              <TextractTableContainer
                width={this.calculatePageWidth()}
                height={params.height}
                scale={scale}
                page={idx + 1}
                wrapper={this.wrapper}
                rotation={params.rotate}
                currentAngle={this.rotateAngle}
                deltaAngle={deltaAngle}
              />
            )}
            {showGrid && (
              <Grid
                mouseYPosition={this.mouseYPosition || 0}
                handleGrid={this.handleGrid}
                hiddenGrid={false}
                width={this.scaledWidth}
                height={params.height}
                scale={scale}
                getStyledPolygons={this.getStyledPolygons}
              />
            )}
            {idx + 1 === 1 && params.status === 'complete' && isVisibleStamp && (
              <ApprovalStampManagement
                currentDimensions={new DimensionsFactory({ width: this.style.width, height: this.style.height })}
                originalDimensions={new DimensionsFactory(originalFirstPageSize)}
                rotation={params.rotate}
              />
            )}
          </PageArea>
        </div>
        {this.isShowButtons() && (
          <DocumentPageControls
            total={total}
            currentNumber={idx + 1}
            isStatement={isStatement}
            isFinancial={isFinancial}
            handleGrid={this.handleGrid}
            setGridButtonRef={this.setGridButtonRef}
            onRotateLeft={onRotateLeft}
            onRotateRight={onRotateRight}
            isSmallActionButton={isSmallActionButton}
            onZoom={onZoom}
            onReset={onReset}
            getIsDisabledZoomButton={getIsDisabledZoomButton}
            isRotateDisabled={textractEnabledForDocument && isFinancial}
            stopPropagationCallback={stopPropagationCallback}
            pageWidth={this.calculatePageWidth()}
          />
        )}
        <div style={{ width: this.calculatePageWidth() }} className={classes.tooltipContainer} id="tooltipContainer" />
      </div>
    );
  }
}

export default compose(withStyles(sheet))(Page);
